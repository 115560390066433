import PropTypes from 'prop-types'
import React from 'react'

import loadable from '@loadable/component'

const PortableText = loadable(() => import('./PortableText'))

const PageBuilderContent = ({ block, raw, index }, props) => {
  const { contentAlignment, alignment, width, content } = block
  const widthClass = width === 'Max' ? 'w-full lg:w-8/12' : 'w-full'
  const textAlignClass = alignment === 'Center' ? 'text-center' : 'text-left'
  const contentAlignClass = contentAlignment === 'Center' ? 'mx-auto' : ''

  return (
    <section
      className={`page-builder-content container grid grid-cols-12 mx-auto my-12 md:my-10 relative ${
        index === 0 ? `mt-16 md:mt-20` : ``
      }`}
    >
      <div className="col-span-12 xl:col-span-10 xl:col-start-2">
        <div className={`${widthClass} ${textAlignClass} ${contentAlignClass}`}>
          <PortableText blocks={content} isBasic />
        </div>
      </div>
    </section>
  )
}

PageBuilderContent.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderContent.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderContent
